var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-container',{staticClass:"base-container-x pt-2",staticStyle:{"min-height":"400px","background-color":"#fff"}},[_c('b-row',{staticClass:"mb-5 justify-content-center"},[_c('b-col',{attrs:{"cols":"12"}},[_c('StepFormRegister',{attrs:{"currentStep":2,"titleListSteps":this.titleListSteps}})],1),_c('b-col',{staticClass:"border rounded py-4 py-md-5 border-light-gray",attrs:{"xl":"9","lg":"9","sm":"12","md":"12"}},[_c('h3',{staticClass:"content-header-title text-primary-dark"},[_vm._v(_vm._s(_vm.$t('Yoga Information')))]),_c('div',{staticClass:"mt-4"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-form-group',[_c('label',{staticClass:"text-primary-dark font-weight-600",attrs:{"for":"apply_for"}},[_vm._v(_vm._s(_vm.$t('Applying for'))+" "),_c('span',{staticClass:"text-danger-light"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":_vm.$t('Applying for'),"rules":"required","autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"options":_vm.school_type_options,"multiple":true,"placeholder":_vm.$t('Applying for'),"selectedLabel":_vm.$t('Selected'),"deselectLabel":_vm.$t('Press enter to remove'),"selectLabel":_vm.$t('Press enter to select'),"label":"school_type_name","track-by":"school_type"},model:{value:(_vm.form.school_type),callback:function ($$v) {_vm.$set(_vm.form, "school_type", $$v)},expression:"form.school_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('div',[_c('label',{staticClass:"text-primary-dark font-weight-600 text-left",attrs:{"for":"level_of_registration"}},[_vm._v(_vm._s(_vm.$t('Level of Registration'))),_c('span',{staticClass:"text-danger-light"},[_vm._v("*")])]),_c('b-form-checkbox',{staticClass:"ml-2 text-right d-inline",attrs:{"name":"check-button","switch":""},model:{value:(_vm.levelTypechecked),callback:function ($$v) {_vm.levelTypechecked=$$v},expression:"levelTypechecked"}},[_c('b',[_vm._v(_vm._s(_vm.levelTypechecked ? _vm.$t('Lifetime') : _vm.$t('Annual')))]),_vm._v(" "),_c('router-link',{attrs:{"to":{ name: 'feeDetails' },"target":"_blank"}},[_c('b-icon',{attrs:{"icon":"question-circle","title":_vm.$t('Terms and Conditions'),"aria-label":"Help"}})],1)],1)],1),_c('validation-provider',{attrs:{"name":_vm.$t('Level of Registration'),"rules":"required","autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"options":_vm.school_level_options,"multiple":true,"placeholder":_vm.$t('Level of Registration'),"selectedLabel":_vm.$t('Selected'),"deselectLabel":_vm.$t('Press enter to remove'),"selectLabel":_vm.$t('Press enter to select'),"label":"school_level_name","track-by":"school_level"},model:{value:(_vm.form.school_level),callback:function ($$v) {_vm.$set(_vm.form, "school_level", $$v)},expression:"form.school_level"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('label',{staticClass:"text-primary-dark font-weight-600",attrs:{"for":"other_title"}},[_vm._v(_vm._s(_vm.$t('Other Level of Registration'))+" "),_c('b-icon',{attrs:{"icon":"question-circle","id":"info-other-level","aria-label":"Help"}}),_c('b-tooltip',{attrs:{"target":"info-other-level"}},[_vm._v(_vm._s(_vm.$t('If you cannot find Level of Registration provided above, please specify here')))])],1),_c('b-form-input',{attrs:{"id":"other_title"},model:{value:(_vm.form.other_level),callback:function ($$v) {_vm.$set(_vm.form, "other_level", $$v)},expression:"form.other_level"}})],1),_c('b-form-group',[_c('label',{staticClass:"text-primary-dark font-weight-600",attrs:{"for":"training_type"}},[_vm._v(_vm._s(_vm.$t('Type of Training'))+" "),_c('span',{staticClass:"text-danger-light"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":_vm.$t('Type of Training'),"rules":"required","autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"training_type","options":_vm.schoolFormData.school_training_types,"value-field":"id","text-field":"name"},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v(_vm._s(_vm.$t('Click Here')))])]},proxy:true}],null,true),model:{value:(_vm.form.school_training_type),callback:function ($$v) {_vm.$set(_vm.form, "school_training_type", $$v)},expression:"form.school_training_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('label',{staticClass:"text-primary-dark font-weight-600",attrs:{"for":"style_of_yoga"}},[_vm._v(_vm._s(_vm.$t('Style of Yoga'))+" "),_c('span',{staticClass:"text-danger-light"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":_vm.$t('Style of Yoga'),"rules":"required","autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"options":_vm.school_style_of_yoga_options,"multiple":true,"placeholder":_vm.$t('Level of Registration'),"selectedLabel":_vm.$t('Selected'),"deselectLabel":_vm.$t('Press enter to remove'),"selectLabel":_vm.$t('Press enter to select'),"label":"school_style_of_yoga_name","track-by":"school_style_of_yoga"},model:{value:(_vm.form.school_style_of_yoga),callback:function ($$v) {_vm.$set(_vm.form, "school_style_of_yoga", $$v)},expression:"form.school_style_of_yoga"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('label',{staticClass:"text-primary-dark font-weight-600",attrs:{"for":"description_yoga_style"}},[_vm._v(_vm._s(_vm.$t('Description of Your Yoga Style')))]),_c('b-form-textarea',{attrs:{"id":"description_yoga_style","placeholder":_vm.$t('Enter description'),"rows":"3","max-rows":"6"},model:{value:(_vm.form.school_description_style_of_yoga),callback:function ($$v) {_vm.$set(_vm.form, "school_description_style_of_yoga", $$v)},expression:"form.school_description_style_of_yoga"}})],1),_c('h3',{staticClass:"content-header-title text-primary-dark mt-4"},[_vm._v(_vm._s(_vm.$t('Faculty Information')))]),_c('b-form-group',[_c('label',{staticClass:"text-primary-dark font-weight-600",attrs:{"for":"amount_teacher"}},[_vm._v(_vm._s(_vm.$t('How many teachers working at your School / Studio ?'))+" "),_c('span',{staticClass:"text-danger-light"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":_vm.$t('How many teachers working at your School / Studio ?'),"rules":"required","autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"amount_teacher","options":_vm.schoolFormData.school_list_teachers,"value-field":"id","text-field":"name"},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v(_vm._s(_vm.$t('Click Here')))])]},proxy:true}],null,true),model:{value:(_vm.form.school_list_teacher),callback:function ($$v) {_vm.$set(_vm.form, "school_list_teacher", $$v)},expression:"form.school_list_teacher"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('label',{staticClass:"text-primary-dark font-weight-600",attrs:{"for":"instructors_name"}},[_vm._v(_vm._s(_vm.$t('Instructors name'))+" "),_c('span',{staticClass:"text-danger-light"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":_vm.$t('Instructors name'),"rules":"required","autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"instructors_name","type":"text","placeholder":_vm.$t('Enter instructors name')},model:{value:(_vm.form.instructors_name),callback:function ($$v) {_vm.$set(_vm.form, "instructors_name", $$v)},expression:"form.instructors_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"description":_vm.$t('* For those who wish to open yoga training programs, please specify yoga educational background for Instructors.')}},[_c('label',{staticClass:"text-primary-dark font-weight-600",attrs:{"for":"instructors_certification"}},[_vm._v(_vm._s(_vm.$t('Instructors certification details'))+" "),_c('span',{staticClass:"text-danger-light"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":_vm.$t('Instructors certification details'),"rules":"required","autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"instructors_certification","options":_vm.schoolFormData.school_main_teacher_certifications,"value-field":"id","text-field":"name"},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v(_vm._s(_vm.$t('Click Here')))])]},proxy:true}],null,true),model:{value:(_vm.form.school_main_teacher_certification),callback:function ($$v) {_vm.$set(_vm.form, "school_main_teacher_certification", $$v)},expression:"form.school_main_teacher_certification"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('label',{staticClass:"text-primary-dark font-weight-600",attrs:{"for":"instructor_expert_year"}},[_vm._v(_vm._s(_vm.$t('How many years has instructors been teaching yoga?'))+" "),_c('span',{staticClass:"text-danger-light"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":_vm.$t('How many years has instructors been teaching yoga?'),"rules":"required","autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"instructor_expert_year","options":_vm.schoolFormData.school_main_teacher_teaching,"value-field":"id","text-field":"name"},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v(_vm._s(_vm.$t('Click Here')))])]},proxy:true}],null,true),model:{value:(_vm.form.school_main_teacher_teaching),callback:function ($$v) {_vm.$set(_vm.form, "school_main_teacher_teaching", $$v)},expression:"form.school_main_teacher_teaching"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('label',{staticClass:"text-primary-dark font-weight-600",attrs:{"for":"instructor_expert"}},[_vm._v(_vm._s(_vm.$t('Has the main teacher been teaching yoga classes/courses at least 3,000 - 4,500 hours of after graduated?'))+" "),_c('span',{staticClass:"text-danger-light"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":_vm.$t('Has the main teacher been teaching yoga classes/courses at least 3,000 - 4,500 hours of after graduated?'),"rules":"required","autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"instructor_expert","options":_vm.optionMainTeacherBeenTeaching},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v(_vm._s(_vm.$t('Click Here')))])]},proxy:true}],null,true),model:{value:(_vm.form.is_teacher_been_teaching),callback:function ($$v) {_vm.$set(_vm.form, "is_teacher_been_teaching", $$v)},expression:"form.is_teacher_been_teaching"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('label',{staticClass:"text-primary-dark font-weight-600",attrs:{"for":"instructors_certification"}},[_vm._v(_vm._s(_vm.$t('Do you issue a certificate to your graduates?'))+" "),_c('span',{staticClass:"text-danger-light"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":_vm.$t('Do you issue a certificate to your graduates?'),"rules":"required","autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"instructors_certification","options":_vm.schoolFormData.school_issue_certificates,"value-field":"id","text-field":"name"},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v(_vm._s(_vm.$t('Click Here')))])]},proxy:true}],null,true),model:{value:(_vm.form.school_issue_certification),callback:function ($$v) {_vm.$set(_vm.form, "school_issue_certification", $$v)},expression:"form.school_issue_certification"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-row',{staticClass:"justify-content-center mt-4"},[_c('b-button',{staticClass:"btn-default text-primary-dark font-weight-bold px-5",attrs:{"type":"reset","pill":""},on:{"click":_vm.onPrevious}},[_vm._v(_vm._s(_vm.$t('Previous')))]),_c('b-button',{staticClass:"btn-primary-dark ml-3 font-weight-bold px-5",attrs:{"type":"submit","pill":"","disabled":_vm.isLoading}},[(_vm.isLoading)?_c('b-spinner',{staticClass:"mr-3",attrs:{"small":"","label":"Loading..."}}):_vm._e(),_vm._v(_vm._s(_vm.$t('Next')))],1)],1)],1)]}}])})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }