<template>
  <div>
    <b-container class="base-container-x pt-2" style="min-height:400px;background-color:#fff">
      <b-row class="mb-5 justify-content-center">
        <b-col cols="12">
          <StepFormRegister :currentStep="2" :titleListSteps="this.titleListSteps" />
        </b-col>
        <b-col xl="9" lg="9" sm="12" md="12" class="border rounded py-4 py-md-5 border-light-gray">
          <h3 class="content-header-title text-primary-dark">{{ $t('Yoga Information') }}</h3>
          <div class="mt-4">
            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
              <b-form-group>
                <label for="apply_for" class="text-primary-dark font-weight-600">{{ $t('Applying for') }} <span class="text-danger-light">*</span></label>
                <validation-provider
                  :name="$t('Applying for')"
                  rules="required"
                  autocomplete="off"
                  v-slot="{errors}"
                >
                <multiselect
                 v-model="form.school_type"
                 :options="school_type_options"
                 :multiple="true"
                 :placeholder="$t('Applying for')"
                 :selectedLabel="$t('Selected')"
                 :deselectLabel="$t('Press enter to remove')"
                 :selectLabel="$t('Press enter to select')"
                 label="school_type_name"
                 track-by="school_type"
                 ></multiselect>
                <!-- <b-form-select
                  id="apply_for"
                  v-model="form.school_type"
                  :options="schoolFormData.school_types"
                  value-field="id"
                  text-field="name">
                  <template #first>
                    <b-form-select-option :value="null" disabled>{{ $t('Click Here') }}</b-form-select-option>
                  </template>
                </b-form-select> -->
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <div>
                  <label for="level_of_registration" class="text-primary-dark font-weight-600 text-left">{{ $t('Level of Registration') }}<span class="text-danger-light">*</span></label>
                  <b-form-checkbox v-model="levelTypechecked" name="check-button" switch class="ml-2 text-right d-inline">
                    <b>{{ levelTypechecked ? $t('Lifetime') : $t('Annual') }}</b> <router-link :to="{ name: 'feeDetails' }" target="_blank"><b-icon icon="question-circle" :title="$t('Terms and Conditions')" aria-label="Help"></b-icon></router-link>
                  </b-form-checkbox>
                </div>
                <validation-provider
                  :name="$t('Level of Registration')"
                  rules="required"
                  autocomplete="off"
                  v-slot="{errors}"
                >
                <multiselect
                 v-model="form.school_level"
                 :options="school_level_options"
                 :multiple="true"
                 :placeholder="$t('Level of Registration')"
                 :selectedLabel="$t('Selected')"
                 :deselectLabel="$t('Press enter to remove')"
                 :selectLabel="$t('Press enter to select')"
                 label="school_level_name"
                 track-by="school_level"
                 ></multiselect>
                <!-- <b-form-select
                  id="level_of_registration"
                  v-model="form.school_level"
                  :options="schoolFormData.school_levels"
                  value-field="id"
                  text-field="name">
                  <template #first>
                    <b-form-select-option :value="null" disabled>{{ $t('Click Here') }}</b-form-select-option>
                  </template>
                </b-form-select> -->
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- <b-form-group>
                <label for="additional_level_of_registration" class="text-primary-dark font-weight-600">{{ $t('Additional Level of Registration (Optional)') }}</label>
                <multiselect
                 v-model="form.school_level_additional"
                 :options="school_level_additional_options"
                 :multiple="true"
                 :placeholder="$t('Level of Registration')"
                 :selectedLabel="$t('Selected')"
                 :deselectLabel="$t('Press enter to remove')"
                 :selectLabel="$t('Press enter to select')"
                 label="school_level_additional_name"
                 track-by="school_level_additional"
                 ></multiselect>
              </b-form-group> -->
              <b-form-group>
                <label for="other_title" class="text-primary-dark font-weight-600">{{ $t('Other Level of Registration') }} <b-icon icon="question-circle" id="info-other-level" aria-label="Help"></b-icon><b-tooltip target="info-other-level">{{ $t('If you cannot find Level of Registration provided above, please specify here') }}</b-tooltip></label>
                <b-form-input
                  id="other_title"
                  v-model="form.other_level"
                  ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label for="training_type" class="text-primary-dark font-weight-600">{{ $t('Type of Training') }} <span class="text-danger-light">*</span></label>
                <validation-provider
                  :name="$t('Type of Training')"
                  rules="required"
                  autocomplete="off"
                  v-slot="{errors}"
                >
                <b-form-select
                  id="training_type"
                  v-model="form.school_training_type"
                  :options="schoolFormData.school_training_types"
                  value-field="id"
                  text-field="name">
                  <template #first>
                    <b-form-select-option :value="null" disabled>{{ $t('Click Here') }}</b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <label for="style_of_yoga" class="text-primary-dark font-weight-600">{{ $t('Style of Yoga') }} <span class="text-danger-light">*</span></label>
                <validation-provider
                  :name="$t('Style of Yoga')"
                  rules="required"
                  autocomplete="off"
                  v-slot="{errors}"
                >
                <multiselect
                 v-model="form.school_style_of_yoga"
                 :options="school_style_of_yoga_options"
                 :multiple="true"
                 :placeholder="$t('Level of Registration')"
                 :selectedLabel="$t('Selected')"
                 :deselectLabel="$t('Press enter to remove')"
                 :selectLabel="$t('Press enter to select')"
                 label="school_style_of_yoga_name"
                 track-by="school_style_of_yoga"
                 ></multiselect>
                <!-- <b-form-select
                  id="style_of_yoga"
                  v-model="form.school_style_of_yoga"
                  :options="schoolFormData.school_style_of_yogas"
                  value-field="id"
                  text-field="name">
                  <template #first>
                    <b-form-select-option :value="null" disabled>{{ $t('Click Here') }}</b-form-select-option>
                  </template>
                </b-form-select> -->
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <label for="description_yoga_style" class="text-primary-dark font-weight-600">{{ $t('Description of Your Yoga Style') }}</label>
                <b-form-textarea
                  v-model="form.school_description_style_of_yoga"
                  id="description_yoga_style"
                  :placeholder="$t('Enter description')"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>
              <h3 class="content-header-title text-primary-dark mt-4">{{ $t('Faculty Information') }}</h3>
              <b-form-group>
                <label for="amount_teacher" class="text-primary-dark font-weight-600">{{ $t('How many teachers working at your School / Studio ?') }} <span class="text-danger-light">*</span></label>
                <validation-provider
                  :name="$t('How many teachers working at your School / Studio ?')"
                  rules="required"
                  autocomplete="off"
                  v-slot="{errors}"
                >
                <b-form-select id="amount_teacher"
                  v-model="form.school_list_teacher"
                  :options="schoolFormData.school_list_teachers"
                  value-field="id"
                  text-field="name">
                  <template #first>
                    <b-form-select-option :value="null" disabled>{{ $t('Click Here') }}</b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <label for="instructors_name" class="text-primary-dark font-weight-600">{{ $t('Instructors name') }} <span class="text-danger-light">*</span></label>
                <validation-provider
                  :name="$t('Instructors name')"
                  rules="required"
                  autocomplete="off"
                  v-slot="{errors}"
                >
                <b-form-input
                  id="instructors_name"
                  v-model="form.instructors_name"
                  type="text"
                  :placeholder="$t('Enter instructors name')"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                :description="$t('* For those who wish to open yoga training programs, please specify yoga educational background for Instructors.')"
              >
                <label for="instructors_certification" class="text-primary-dark font-weight-600">{{ $t('Instructors certification details') }} <span class="text-danger-light">*</span></label>
                <validation-provider
                  :name="$t('Instructors certification details')"
                  rules="required"
                  autocomplete="off"
                  v-slot="{errors}"
                >
                <b-form-select
                  id="instructors_certification"
                  v-model="form.school_main_teacher_certification"
                  :options="schoolFormData.school_main_teacher_certifications"
                  value-field="id"
                  text-field="name"
                >
                <template #first>
                  <b-form-select-option :value="null" disabled>{{ $t('Click Here') }}</b-form-select-option>
                </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <label for="instructor_expert_year" class="text-primary-dark font-weight-600">{{ $t('How many years has instructors been teaching yoga?') }} <span class="text-danger-light">*</span></label>
                <validation-provider
                  :name="$t('How many years has instructors been teaching yoga?')"
                  rules="required"
                  autocomplete="off"
                  v-slot="{errors}"
                >
                  <b-form-select
                    id="instructor_expert_year"
                    v-model="form.school_main_teacher_teaching"
                    :options="schoolFormData.school_main_teacher_teaching"
                    value-field="id"
                    text-field="name"
                  >
                  <template #first>
                    <b-form-select-option :value="null" disabled>{{ $t('Click Here') }}</b-form-select-option>
                  </template>
                  </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <label for="instructor_expert" class="text-primary-dark font-weight-600">{{ $t('Has the main teacher been teaching yoga classes/courses at least 3,000 - 4,500 hours of after graduated?') }} <span class="text-danger-light">*</span></label>
                <validation-provider
                  :name="$t('Has the main teacher been teaching yoga classes/courses at least 3,000 - 4,500 hours of after graduated?')"
                  rules="required"
                  autocomplete="off"
                  v-slot="{errors}"
                >
                <b-form-select
                  id="instructor_expert"
                  v-model="form.is_teacher_been_teaching"
                  :options="optionMainTeacherBeenTeaching"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled>{{ $t('Click Here') }}</b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <label for="instructors_certification" class="text-primary-dark font-weight-600">{{ $t('Do you issue a certificate to your graduates?') }} <span class="text-danger-light">*</span></label>
                <validation-provider
                  :name="$t('Do you issue a certificate to your graduates?')"
                  rules="required"
                  autocomplete="off"
                  v-slot="{errors}"
                >
                <b-form-select
                  id="instructors_certification"
                  v-model="form.school_issue_certification"
                  :options="schoolFormData.school_issue_certificates"
                  value-field="id"
                  text-field="name"
                >
                <template #first>
                  <b-form-select-option :value="null" disabled>{{ $t('Click Here') }}</b-form-select-option>
                </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-row class="justify-content-center mt-4">
                <b-button type="reset" @click="onPrevious" pill class="btn-default text-primary-dark font-weight-bold px-5">{{ $t('Previous') }}</b-button>
                <b-button type="submit" pill :disabled="isLoading" class="btn-primary-dark ml-3 font-weight-bold px-5"><b-spinner small class="mr-3" v-if="isLoading"  label="Loading..."/>{{ $t('Next') }}</b-button>
              </b-row>
            </b-form>
            </ValidationObserver>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>

import StepFormRegister from '../../components/form/StepFormRegister'
import Api from '../../api/api'
import Multiselect from 'vue-multiselect'

export default {
  name: 'SchoolRegisterYogaAndFaculty',
  title: ' | School register SchoolRegisterYogaAndFaculty',
  components: {
    StepFormRegister,
    Multiselect
  },
  data () {
    return {
      currentStep: 2,
      titleListSteps: [this.$t('Start'), this.$t('School info'), this.$t('Yoga info'), this.$t('Document'), this.$t('Payment'), this.$t('Finished')],
      options: [
        { value: null, text: 'Please select an option' }
      ],
      optionMainTeacherBeenTeaching: [
        { value: true, text: this.$t('Yes') },
        { value: false, text: this.$t('No') }
      ],
      levelTypechecked: true,
      form: {
        id: null,
        school_type: [],
        school_level: [],
        school_level_additional: [],
        other_level: '',
        school_training_type: null,
        school_style_of_yoga: [],
        school_description_style_of_yoga: '',
        school_list_teacher: null,
        instructors_name: '',
        school_main_teacher_certification: null,
        school_main_teacher_teaching: null,
        is_teacher_been_teaching: null,
        school_issue_certification: null
      },
      tempSchool: {},
      schoolFormData: {
        school_levels: []
      },
      school_level_options: [],
      school_level_temp_options: [],
      school_level_additional_options: [],
      school_type_options: [],
      school_style_of_yoga_options: [],
      isLoading: false
    }
  },
  computed: {
    userProfile () {
      return this.$store.getters.userProfile
    }
  },
  watch: {
    levelTypechecked (newLevelTypechecked, oldLevelTypechecked) {
      if (newLevelTypechecked) {
        this.setOptionSchoolLevel(true)
      } else {
        this.setOptionSchoolLevel(false)
      }
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.getSchool()
  },
  methods: {
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    },
    onSubmit () {
      this.$refs.form.validate().then(result => {
        if (result) {
          this.updateSchool()
        }
      })
    },
    updateSchool () {
      this.isLoading = true
      Api.updateSchool(this.form)
        .then((response) => {
          this.isLoading = false
          this.$router.push({ name: 'schoolRegisterDocument' })
        })
        .catch(() => {
          this.isLoading = false
          const textError = this.$t('Unsuccessfully register yoga school')
          this.$notify({
            group: 'error',
            title: this.$t('Register yoga school'),
            text: textError
          })
        })
    },
    getFormdata () {
      Api.shoolFormData().then(response => {
        if (response.data) {
          this.schoolFormData = response.data
          const schoolLevelOptions = []
          const schoolTypeOptions = []
          const schoolLevelAdditionalOptions = []
          const schoolStyleOfYogaOptions = []
          const schoolId = this.form.id
          this.schoolFormData.school_levels.forEach(item => {
            schoolLevelOptions.push({
              school: schoolId,
              school_level: item.id,
              school_level_name: item.name,
              level_type: item.level_type
            })
          })
          this.school_level_options = schoolLevelOptions
          this.school_level_temp_options = schoolLevelOptions
          if (this.form.school_level.length > 0) {
            if (this.form.school_level[0].level_type === 'Lifetime') {
              this.setOptionSchoolLevel(true)
            } else {
              this.levelTypechecked = false
              this.setOptionSchoolLevel(false)
            }
          } else {
            this.setOptionSchoolLevel(true)
          }
          this.schoolFormData.school_types.forEach(item => {
            schoolTypeOptions.push({
              school: schoolId,
              school_type: item.id,
              school_type_name: item.name
            })
          })
          this.school_type_options = schoolTypeOptions
          this.schoolFormData.school_level_additionals.forEach(item => {
            schoolLevelAdditionalOptions.push({
              school: schoolId,
              school_level_additional: item.id,
              school_level_additional_name: item.name
            })
          })
          this.school_level_additional_options = schoolLevelAdditionalOptions
          this.schoolFormData.school_style_of_yogas.forEach(item => {
            schoolStyleOfYogaOptions.push({
              school: schoolId,
              school_style_of_yoga: item.id,
              school_style_of_yoga_name: item.name
            })
          })
          this.school_style_of_yoga_options = schoolStyleOfYogaOptions
        }
      })
    },
    setOptionSchoolLevel (levelTypechecked) {
      if (levelTypechecked) {
        this.school_level_options = this.school_level_temp_options.filter(level => level.level_type === 'Lifetime')
        this.form.school_level = this.form.school_level.filter(level => level.level_type === 'Lifetime')
      } else {
        this.school_level_options = this.school_level_temp_options.filter(level => level.level_type === 'Annual')
        this.form.school_level = this.form.school_level.filter(level => level.level_type === 'Annual')
      }
    },
    getSchool () {
      Api.listSchool({ user: this.userProfile.id }).then(response => {
        if (response.data.results) {
          for (const property in this.form) {
            if (property in response.data.results[0]) {
              this.form[property] = response.data.results[0][property]
            }
          }
          this.tempSchool = response.data.results[0]
          this.getFormdata()
        }
      })
    },
    onPrevious () {
      this.$router.push({ name: 'schoolRegisterInformation' })
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style >
.multiselect__tag {
  background: #3B6098;
}
.multiselect__tag-icon:after {
  color: white;
}
.multiselect__tag-icon:hover {
  background: #3B6098;
}
.multiselect__option:hover{
  background: #3B6098;
}
.multiselect__option.active{
  background: #3B6098;
}
</style>
